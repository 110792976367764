import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { NavBar } from "./components/NavBar";
import { Banner } from "./components/Banner";
import { Products } from "./components/Products";
import { Contact } from "./components/Contact";
import { Footer } from "./components/Footer";
import {  ChatApp } from './components/Chat';
import { Industries } from './components/Industries';
import { Solutions } from './components/Solutions';


function App() {
  return (
    <div className="App">
      <NavBar />
      <Banner />
      <Products />
      <ChatApp/>
      <Industries/>

      {/* <Solutions/> */}

      <Contact />
      <Footer />
    </div>
  );
}

export default App;
