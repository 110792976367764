import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import { ProjectCard } from "./ProjectCard";
import picOne from "../assets/img/1.png";
import picTwo from "../assets/img/2.png";
import picThree from "../assets/img/3.png";
import picFour from "../assets/img/4.png";
import picFive from "../assets/img/5.png";

import colorSharp2 from "../assets/img/color-sharp2.png";
import 'animate.css';
import TrackVisibility from 'react-on-screen';

export const Products = () => {

  const products = [
    {
      title: "AI Conversational Chatbots",
      description: "Discover advanced chatbots, powered by NLP, engaging users in real-time text conversations. Efficiently handling queries, offering support, and enhancing user experience, they evolve continuously for streamlined operations.",
      imgUrl: picOne,
    },
    {
      title: "AI Conversational Voice Chat",
      description: "Elevate customer interactions with AI voice chat solutions, offering natural voice-based communication. From answering FAQs to real-time assistance, these voice bots enhance user engagement and accessibility.",
      imgUrl: picTwo,
    },
    {
      title: "AI Conversational Avatars",
      description: "Our AI-powered conversational avatars deliver a human-like experience, combining visual appeal with intelligent interactions. Perfect for virtual assistants or customer service, they enhance brand engagement through customizable voice and text conversations.",
      imgUrl: picThree,
    },
    {
      title: "AI Voice Calls and Lead Generation",
      description: "Boost lead generation with AI-driven voice calls that offer personalized, real-time interactions. Automate outreach and enhance customer engagement for more efficient business growth.",
      imgUrl: picFour,
    },
    {
      title: "AI Executive Assistants",
      description: "Streamline your workflow with AI executive assistants, providing smart scheduling, task management, and real-time support. Enhance productivity and efficiency with intelligent, automated assistance.",
      imgUrl: picFive,
    },
 
  ];

  return (
    <section className="project" id="products">
      <Container>
        <Row>
          <Col size={12}>
            <TrackVisibility>
              {({ isVisible }) =>
              <div className={isVisible ? "animate__animated animate__fadeIn": ""}>
                <h2>Products</h2>

                <br/>
                <Row>
                        {
                          products.map((project, index) => {
                            return (
                              <ProjectCard
                                key={index}
                                {...project}
                                />
                            )
                          })
                        }
                      </Row>
              </div>}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
      <img className="background-image-right" src={colorSharp2}></img>
    </section>
  )
}
