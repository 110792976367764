import { useState } from "react";
import { Container, Col } from "react-bootstrap";
import TrackVisibility from 'react-on-screen';
import '../App.css';

export const ChatApp = () => {
  const [message, setMessage] = useState("");
  const [chats, setChats] = useState([{ role: "chatbot", content: 'Hi, how can i help you?' }]);
  const [isTyping, setIsTyping] = useState(false);

  const chat = async (e, message) => {
    e.preventDefault();
if(isTyping) return;
    if (!message) return;
    setIsTyping(true);

    let msgs = chats;
    msgs.push({ role: "user", content: message });
    setChats(msgs);

    setMessage("");

    fetch("https://avatarapi.onrender.com/oman/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        "input":message,
      }),
    })
      .then((response) =>{
        console.log();
        
        return response.text()})
      .then((data) => {
        msgs.push({ role: "chatbot", content: data });
        setChats(msgs );
        setIsTyping(false);
      })
      .catch((error) => {
        console.log(error);
        setIsTyping(false);

      });
  };


  return (
    <section className="contact" id="chatbot">
      <Container className="container">
        <Col md={12}>
          <TrackVisibility>
            {({ isVisible }) => (
              <div >
                <h2>Our Chat Bot</h2>
                <Container className="chat_container">
  {chats && chats.length
    ? chats.map((chat, index) => (
        <p
          key={index}
          className={chat.role === "user" ? "user_msg" : "chat_msg"}
        >
          <span><b>{chat.role.toUpperCase()}</b></span>
          <span>:</span>
          <span>{chat.content}</span>
        </p>
      ))
    : ""
    
    }
</Container>
  
                <Container className={isTyping ? "" : "hide"}>
                  <p>
                    <i>{isTyping ? "Typing" : ""}</i>
                  </p>
                </Container>
                
                <form action="" onSubmit={(e) => chat(e, message)}>
                  <Col size={12} sm={12} className="px-1">
                    <input
                      type="text"
                      name="message"
                      value={message}
                      placeholder="Type a message here and hit Enter..."
                      onChange={(e) => setMessage(e.target.value)}
                    />
                  </Col>
  
                  <Col size={12} className="px-1">
                    <button type="submit">
                      <span>Send</span>
                    </button>
                  </Col>
                </form>
              </div>
            )}
          </TrackVisibility>
        </Col>
      </Container>
    </section>
  );


}
